import * as childs from './childs'
const DipendenteDettaglio = () => import('./DipendenteDettaglio.vue')
const Elenco = () => import('./Elenco.vue')
const ElencoDipendenti = () => import('./ElencoDipendenti.vue')
export default {
    childs,
    DipendenteDettaglio,
    ElencoDipendenti,
    Elenco,
}
