import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import PeriodoElaborazione from '@paghe/api/generics/periodo-elaborazione'
import { STORES_NAMES } from '@/constants'

export type GlobalStoreState = {
    codTeamAzienda: string | null
    codOperatore: string | null
    codService: string | null
    codStudio: string | null
    darkTheme: boolean
    initApp: boolean
    isSessionExpiredDetected: boolean
    loading: number
    loadingText: string | null
    periodoElab: PeriodoElab | null
    startApp: boolean
    taskCompletati: number[]
    unlockedProtectedList: Array<String>
}

const useGlobalStore = defineStore(
    STORES_NAMES.GLOBAL,
    () => {
        const state: GlobalStoreState = reactive({
            codTeamAzienda: null,
            codOperatore: null,
            codService: null,
            codStudio: null,
            darkTheme: false,
            initApp: true,
            isSessionExpiredDetected: false,
            loading: 0,
            loadingText: null,
            periodoElab: null,
            startApp: true,
            taskCompletati: [],
            unlockedProtectedList: [],
        })

        async function init() {
            try {
                const response = await PeriodoElaborazione.getPeriodoElaborazione({
                    periodoDiRiferimento: null,
                })
                if (response?.data.responseStatus.isSuccessful) {
                    const [year, month] = response.data.data[0].dataPeriodoElaborazione
                        .substr(0, 10)
                        .split('-', 2)
                    state.periodoElab = { year: parseInt(year), month: parseInt(month) }
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        function loadingSet(text?: string) {
            state.loading++
            state.loadingText = text || 'Attendere ...'
        }

        function loadingUnset() {
            if (state.loading > 0) {
                state.loading--
            } else {
                state.loading = 0 // nel caso dovessere essere negativo
                state.loadingText = ''
            }
        }

        const isLoading = computed(() => state.loading > 0)

        function resetPageProtected(name: string) {
            state.unlockedProtectedList = state.unlockedProtectedList.filter(
                el => !el.startsWith(name),
            )
        }

        return {
            init,
            loadingSet,
            loadingUnset,
            isLoading,
            state,
            resetPageProtected,
        }
    },
    {
        persist: [
            {
                paths: [
                    'state.darkTheme',
                    'state.codOperatore',
                    'state.codService',
                    'state.codStudio',
                    'state.codTeamAzienda',
                    'state.taskCompletati',
                ],
                storage: localStorage,
            },
            {
                paths: ['state.startApp'],
                storage: sessionStorage,
            },
        ],
    },
)

export default useGlobalStore
