import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000116',
    GROUP: 'reportLavoratori',
    ICON: 'fa-regular fa-file-export',
    TITLE: 'Report lavoratori',
}

const SETTINGS = Object.freeze({
    DETTAGLIO: {
        NAME: 'reportLavoratori',
        PATH: 'stampe/Report/lavoratori',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Stampe - Report lavoratori',
    },
    REPORT: {
        NAME: 'Lavoratori',
        PATH: 'Lavoratori',
        TAB: { ...tabPropsDefault },
        TITLE: '',
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: pagheViews.stampe.report.lavoratori.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: 'DEF_EXPORT_SP',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    path: SETTINGS.REPORT.PATH,
                    name: SETTINGS.REPORT.NAME,
                    component: pagheViews.stampe.report.lavoratori.childs.Report,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: 'DEF_EXPORT_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
            ],
        },
    ],
}
