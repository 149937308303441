export const ViewAlterazioneName = {
    aElePaga: 'Altera_ElePaga',
    aUnaTantum: 'Altera_UnaTantum',
    aValElePaga: 'Altera_ValorizzaElePaga',
    cDivisore: 'Contratto_Divisore',
    cEnte: 'Contratto_Ente',
    cExFestRol: 'Contratto_FeExFestRol',
    cFestivita: 'Contratto_Festivita',
    cInfortunio: 'Contratto_Infortunio',
    cIstitutoContr: 'Contratto_IstitutoContr',
    cMalattia: 'Contratto_Malattia',
    cMaternita: 'Contratto_Maternita',
    cMensAgg: 'Contratto_MensAgg',
    cProgRetrib: 'Contratto_ProgrRetrib',
    cStraordinario: 'Contratto_Straordinario',
} as const

export type ViewAlterazioneNameKey = keyof typeof ViewAlterazioneName
export type ViewAlterazioneNameValue = (typeof ViewAlterazioneName)[ViewAlterazioneNameKey]

export const NomeTabellaAttiva = {
    aElePaga: 'Altera_ElePaga',
    aValElePaga: 'Altera_ValorizzaElePaga',
    aUnaTantum: 'Altera_UnaTantum',
    cEnte: 'Contratto_Ente',
    cDivisore: 'Contratto_Divisore',
    cExFestRol: 'Contratto_FeExFestRol',
    cInfortunio: 'Contratto_Infortunio',
    cIstitutoContr: 'Contratto_IstitutoContr',
    cMalattia: 'Contratto_Malattia',
    cMaternita: 'Contratto_Maternita',
    cMensAgg: 'Contratto_MensAgg',
    cProgRetrib: 'Contratto_ProgrRetrib',
    cStraordinario: 'Contratto_Straordinario',
    dUPAttivita: 'Ditta_UPAttivita',
    dUPCampoPers: 'Ditta_UPCampoPersonalizzato',
    dUPEnte: 'Ditta_UPEnte',
    dUPEnteFisso: 'Ditta_UPEnteFisso',
    dUPFormula: 'Ditta_UPFormula',
    dUPGest: 'Ditta_UPGestione',
    dUPNotaCedo: 'Ditta_UPNotaCedoFissa',
    dUPOpzione: 'Ditta_UPOpzione',
    dUPOpzioneStampa: 'Ditta_UPOpzioneStampa',
    dUPRagioneSociale: 'Ditta_UPRagioneSociale',
    dUPStorico: 'Ditta_UPStorico',
    scadenza: 'Scadenza',
} as const

export type NomeTabellaAttivaKey = keyof typeof NomeTabellaAttiva
export type NomeTabellaAttivaValue = (typeof NomeTabellaAttiva)[NomeTabellaAttivaKey]

//ENTITA DA DB che hanno una validita'
export const DittaEntity = {
    Appalto: 'Ditta_Appalto',
    Assenteismo: 'Ditta_Assenteismo',
    Banca: 'Ditta_Banca',
    Cantiere: 'Ditta_Cantiere',
    CentroCosto: 'Ditta_CentroCosto',
    CodConto: 'Ditta_CodConto',
    FondoAgricolo: 'Ditta_FondoAgricolo',
    Generale: 'Ditta_Generale',
    GruppoAlterazione: 'Ditta_GruppoAlterazione',
    InailGenerale: 'Ditta_InailGenerale',
    InailPosizione: 'Ditta_InailPosizione',
    InpsUnitaProduttiva: 'Ditta_InpsUnitaProduttiva',
    Rappresentante: 'Ditta_Rappresentante',
    Sostituto: 'Ditta_Sostituto',
    UPAltroEnte: 'Ditta_UPAltroEnte',
    UPAttivita: 'Ditta_UPAttivita',
    UPCampoPersonalizzato: 'Ditta_UPCampoPersonalizzato',
    UPEnte: 'Ditta_UPEnte',
    UPEnteFisso: 'Ditta_UPEnteFisso',
    UPFondiInterprof: 'Ditta_UPFondiInterprof',
    UPFormula: 'Ditta_UPFormula',
    UPGestione: 'Ditta_UPGestione',
    UPNotaCedoFissa: 'Ditta_UPNotaCedoFissaUPNotaCedoFissa',
    UPOpzione: 'Ditta_UPOpzione',
    UPOpzioneStampa: 'Ditta_UPOpzioneStampa',
    UPRagioneSociale: 'Ditta_UPRagioneSociale',
    UPReportElabora: 'Ditta_UPReportElabora',
    UPStampaProfilo: 'Ditta_UPStampaProfilo',
    UPStampaProfiloRaggr: 'Ditta_UPStampaProfiloRaggr',
    UPStorico: 'Ditta_UPStorico',
    UPTabellaAttiva: 'Ditta_UPTabellaAttiva',
    UPVoceDetassazione: 'Ditta_UPVoceDetassazione',
    UPVoceFissa: 'Ditta_UPVoceFissa',
    UPWebAzienda: 'Ditta_UPWebAzienda',
} as const

export type DittaEntityKey = keyof typeof DittaEntity
export type DittaEntityDbValue = (typeof DittaEntity)[DittaEntityKey]

export default { DittaEntity, ViewAlterazioneName, NomeTabellaAttiva }
