import { AxiosResponse } from 'axios'

import { call } from '@/scripts/lib/http'

const BASE_URL = '/Report'

const defaultPayload: Payload = {
    inserimentoIsAlwaysNuovaValidita: false,
    isCountQuery: false,
    isNuovaValidita: false,
    requireTotalCount: false,
}

class API {
    @call({ url: `${BASE_URL}/GetReport`, defaultPayload })
    static async get<P, R>(payload?: P): Promise<AxiosResponse<BasicResponseData<R>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetImmagineSfondoReport`, defaultPayload })
    static async gePdfImage<P, R>(
        payload?: P,
    ): Promise<AxiosResponse<BasicResponsePayload<R>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetListaTemplateSfondo`, defaultPayload })
    static async gePdfList<P, R>(
        payload?: P,
    ): Promise<AxiosResponse<BasicResponsePayload<R>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveReport`, defaultPayload })
    static async save<P, R>(payload?: P): Promise<AxiosResponse<BasicResponsePayload<R>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/EseguiReportDipendenteJob`, defaultPayload })
    static async eseguiReportDipendenteJob<P, R>(
        payload?: P,
    ): Promise<AxiosResponse<BasicResponsePayload<R>> | null> {
        return null
    }
}

export default {
    get: API.get<GetReport, ExportReport>,
    getPdfImage: API.gePdfImage<GetImmagineSfondoReport, ImmagineSfondoReport>,
    getPdfList: API.gePdfList<GetListaTemplateSfondo, ListaTemplateSfondo>,
    save: API.save<SaveReportPayload, Omit<SaveReportPayload, keyof BasePayload>>,
    eseguiReportDipendenteJob: API.eseguiReportDipendenteJob<
        EseguiReportDipendenteJobPayload,
        BasicResponsePayload
    >,
}
