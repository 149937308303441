import moment from 'moment'

export interface DateRec {
    year: string | number
    month?: string | number
    day?: string | number
}

export type DateType = Date | DateRec | string | moment.Moment

const FORMATS = {
    DATE: 'DD/MM/YYYY',
    DATE_ISO: 'YYYY-MM-DD',
    DATE_NO_SLASH: 'DDMMYYYY',
    DATE_TIME: 'DD/MM/YYYY - HH:mm',
    DATE_T_TIME: 'DD/MM/YYYY[T]HH:mm:ss',
    DAY_MONTH: 'DD/MM',
    DAY_MONTH_TIME: 'DD/MM - HH:mm',
    MONTH_YEAR: 'MM/YYYY',
    TIME: 'HH:mm',
    YEAR: 'YYYY',
    YEAR_MONT: 'YYYY-MM',
} as const

export function dateOk(date: DateType, required?: boolean): boolean {
    return date ? toMoment(date)?.isValid() || false : !required
}

type DateFormat =
    | 'date'
    | 'dateIso'
    | 'dateNoSlash'
    | 'dateTime'
    | 'dayMonth'
    | 'dayMonthTime'
    | 'monthYear'
export function format(date: DateType | string, format?: DateFormat): string {
    if (!date) return ''
    let _format: string = FORMATS.DATE
    switch (format) {
        case 'dateIso':
            _format = FORMATS.DATE_ISO
            break
        case 'dateNoSlash':
            _format = FORMATS.DATE_NO_SLASH
            break
        case 'dateTime':
            _format = FORMATS.DATE_TIME
            break
        case 'dayMonth':
            _format = FORMATS.DAY_MONTH
            break
        case 'dayMonthTime':
            _format = FORMATS.DAY_MONTH_TIME
            break
        case 'monthYear':
            _format = FORMATS.MONTH_YEAR
            break
    }
    return moment(toMoment(date)).format(_format)
}

export function rangeDateOk(date1: DateType, date2: DateType): boolean {
    if (!dateOk(date1, true) || !dateOk(date2, true)) return true
    return !toMoment(date1)!.isAfter(toMoment(date2))
}

export function toMoment(date: any, nullToday = false, byFormat?: string): null | moment.Moment {
    if (!date) return nullToday ? moment() : null
    if (moment.isMoment(date)) {
        return date
    } else {
        switch (date.constructor) {
            case Date:
                return moment(date)
            case String: {
                const mDate = moment(
                    date,
                    byFormat || [
                        FORMATS.DATE,
                        FORMATS.DATE_ISO,
                        FORMATS.DATE_NO_SLASH,
                        FORMATS.DATE_TIME,
                        FORMATS.DATE_T_TIME,
                        FORMATS.DAY_MONTH,
                        FORMATS.DAY_MONTH_TIME,
                        FORMATS.MONTH_YEAR,
                        FORMATS.YEAR,
                        FORMATS.YEAR_MONT,
                        moment.ISO_8601,
                    ],
                    'it',
                    !!byFormat,
                )
                return mDate.isValid() ? mDate : null
            }
            default:
                if ('year' in date && 'month' in date) {
                    const dateRec = { ...date }
                    dateRec.year = parseInt(dateRec.year)
                    dateRec.month = parseInt(dateRec.month) - 1
                    return moment(dateRec)
                }
                return null
        }
    }
}

export function toObject(date: DateType | undefined | null): DateRec | null {
    const mDate = toMoment(date)
    return mDate?.isValid()
        ? { year: mDate.year(), month: mDate.month() + 1, day: mDate.date() }
        : null
}

export function toISO(
    date: DateType | undefined,
    noTime: boolean = false,
    byFormat?: string,
): string | null {
    if (!date) return null
    const mDate = toMoment(date, false, byFormat)
    return mDate?.isValid() ? mDate.format('YYYY-MM-DD' + (noTime ? '' : '[T]HH:mm:ss')) : null
}

export function isBetween(
    dateToMatch: DateType | null = '',
    firsDate: DateType | null = '',
    lastDate: DateType | null = '',
    inclusivity: '[]' | '()' | '[)' | '(]' | undefined = '[]',
    granularity: moment.unitOfTime.StartOf = null,
): boolean {
    if (!dateToMatch || !firsDate || !lastDate) return false
    //In futuro possibilita di specificare startOf e endOf('month') per le varie date
    const mFirstDate = toMoment(firsDate)
    const mLastDate = toMoment(lastDate)
    const mDateToMatch = toMoment(dateToMatch)

    return !!mDateToMatch?.isBetween(mFirstDate, mLastDate, granularity, inclusivity)
}

export function isAfter(
    dateToMatch: string | null = '',
    dateAfter: string | null = '',
    sameAllowed: boolean = false,
    granularity: moment.unitOfTime.StartOf = null,
) {
    if (!dateToMatch || !dateAfter) return false

    const mDateToMatch = toMoment(dateToMatch)
    const mDateAfter = toMoment(dateAfter)

    return sameAllowed
        ? !!mDateToMatch?.isSameOrAfter(mDateAfter, granularity)
        : !!mDateToMatch?.isAfter(mDateAfter, granularity)
}

export function isBefore(
    dateToMatch: string | null = '',
    dateBefore: string | null = '',
    sameAllowed: boolean = false,
    granularity: moment.unitOfTime.StartOf = null,
) {
    if (!dateToMatch || !dateBefore) return false

    return !isAfter(dateToMatch, dateBefore, sameAllowed, granularity)
}

export function getPeriodo(dataInizio: string, dataFine: string, periodoDiRiferimento: DateRec) {
    if (isAfter(toISO(periodoDiRiferimento), dataFine, true)) return toObject(dataFine as string)
    if (isBefore(toISO(periodoDiRiferimento), dataInizio, true))
        return toObject(dataInizio as string)
    return periodoDiRiferimento
}

export default {
    FORMATS,
    dateOk,
    format,
    isAfter,
    isBefore,
    isBetween,
    getPeriodo,
    rangeDateOk,
    toMoment,
    toObject,
    toISO,
}
