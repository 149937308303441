import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/PeriodoElaborazione'

class API {
    @call({ url: `${BASE_URL}/Get` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<PeriodoElaborazione>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/GetPeriodoElaborazione` })
    static async getPeriodoElaborazione<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<PeriodoElaborazione>> | null> {
        return null
    }

    // @call({ url: `${BASE_URL}/CreatePeriodoElaborazione` })
    // static async createPeriodoElaborazione(
    //     payload: SaveDiarioCodiceCommand,
    // ): Promise<AxiosResponse<BasicResponsePayload<SaveDiarioCodiceCommand>> | null> {
    //     return null
    // }
}

export default {
    get: API.get,
    getPeriodoElaborazione: API.getPeriodoElaborazione,
    //createPeriodoElaborazione: API.createPeriodoElaborazione,
}
