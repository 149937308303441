import { http } from '@/scripts/lib/http.js'
import { getDataDb } from '@/composables/indexDB'
import { INDEXED_DB } from '@/constants/index'
import { get } from 'lodash'
const BASE_URL = '/Banca'

const payload: Payload = {
    requireTotalCount: false,
    isCountQuery: false,

    periodoDiRiferimento: {
        year: 1900,
        month: 1,
        day: 1,
    },
}

export default class APIBanca {
    static async get(params?: Payload, force: boolean = false) {
        function getAPI() {
            return http.post(`${BASE_URL}/GetBanche`, {
                ...payload,
                ...params,
            })
        }
        if (force) {
            return getAPI()
        } else {
            const res: any = await getDataDb(INDEXED_DB.STORES.BANCHE, params)

            if (res?.responseStatus?.isSuccessful === true)
                return get(res, `${INDEXED_DB.STORES.BANCHE.DATA_ROOT}`, [])
            return await get(await getAPI(), `data.${INDEXED_DB.STORES.BANCHE.DATA_ROOT}`, [])
        }
    }
}
