import { useGlobalStore } from '@/stores'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'mensilita',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'mens.agg',
    CODVOCEMENU: '000009',
    CODPERMESSO: 'MOVIMENTO', //TODO: DA CAMBIARE CHIEDERE A Davide
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: Number(route.params.day),
        id: Number(route.params.id),
        idPadre: Number(route.params.idPadre),
        isSp: String(route.params.isSp) === '1',
    }
}

export const SETTINGS = Object.freeze({
    DITTE_LISTA: {
        TITLE: 'Elenco Ditte - Mensilita - Gestione - Paghe',
        LABEL: 'Elenco Ditte Mensilita',
        PATH: 'mensilitaDitteList',
        NAME: 'ElencoDitteMensilita',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTI_LISTA: {
        NAME: 'menDipteELenco',
        PATH: 'mensilita/dipendenti/elenco-dipendenti/:tipoMensilita/:periodo/:idDitta/:idUP/',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Mensilita dipendenti',
    },
    DIPENDENTE_DETTAGLIO: {
        NAME: 'MenDiptiDettaglio',
        PATH: 'mensilita/dipendenti/:tipoMensilita/:periodo/:idDitta/:idUP/:id',
        TITLE: 'Paghe - Mensilita mese dipendenti',
    },
    DIPENDENTE_MENSILITA: {
        LABEL: 'Dipendente mensilita',
        NAME: 'MenDiptiMensilita',
        PATH: 'dettaglioDipte',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DITTE_LISTA.PATH,
            name: SETTINGS.DITTE_LISTA.NAME,
            component: pagheViews.mensilita.Elenco,
            meta: {
                title: SETTINGS.DITTE_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                idPaginaMsg: 'MA00',
            },
            props: true,
        },
        {
            path: SETTINGS.DIPENDENTI_LISTA.PATH,
            name: SETTINGS.DIPENDENTI_LISTA.NAME,
            component: pagheViews.mensilita.ElencoDipendenti,
            meta: {
                title: SETTINGS.DIPENDENTI_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                idPaginaMsg: 'MA01',
            },
            props: true,
        },
        {
            path: SETTINGS.DIPENDENTE_DETTAGLIO.PATH,
            name: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
            props: true,
            component: pagheViews.mensilita.DipendenteDettaglio,
            meta: {
                title: SETTINGS.DIPENDENTE_DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    component: pagheViews.mensilita.childs.Dipendenti,
                    label: SETTINGS.DIPENDENTE_MENSILITA.LABEL,
                    name: SETTINGS.DIPENDENTE_MENSILITA.NAME,
                    path: SETTINGS.DIPENDENTE_MENSILITA.PATH,
                    tab: SETTINGS.DIPENDENTE_MENSILITA.TAB,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'MA02',
                    },
                    orderWizard: 0,
                    props: true,
                },
            ],
        },
    ],
}
