import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000085',
    GROUP: 'MensiliDipendente',
    ICON: 'fa-regular fa-file-export',
    TITLE: 'Stampe mensili dipendente',
}

const SETTINGS = Object.freeze({
    DETTAGLIO: {
        NAME: 'MensiliDipendente',
        PATH: 'stampe/mensili/dipendente',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Stampe - Mensili - Dipendente - Prospetto costo dipendente',
    },
    PROSPETTOCOSTOLAVORATORE: {
        NAME: 'ProspettoCostoLavoratore',
        PATH: 'ProspettoCostoLavoratore',
        TAB: { ...tabPropsDefault },
        TITLE: '',
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: pagheViews.stampe.mensili.dipendente.prospettoCosto.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: 'DEF_EXPORT_SP', // TODO - check
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                idPaginaMsg: 'PCOSTO',
            },
            children: [
                {
                    path: SETTINGS.PROSPETTOCOSTOLAVORATORE.PATH,
                    name: SETTINGS.PROSPETTOCOSTOLAVORATORE.NAME,
                    component:
                        pagheViews.stampe.mensili.dipendente.prospettoCosto.childs
                            .ProspettoCostoLavoratore,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: 'DEF_EXPORT_SP', // TODO - check
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                },
            ],
        },
    ],
}
