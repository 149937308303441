export const ContrattoEntity = {
    Divisore: 'Contratto_Divisore',
    Ente: 'Contratto_Ente',
    Festivita: 'Contratto_Festivita',
    Infortunio: 'Contratto_Infortunio',
    IstitutoContr: 'Contratto_IstitutoContr',
    FeExFestRol: 'Contratto_FeExFestRol',
    Malattia: 'Contratto_Malattia',
    Maternita: 'Contratto_Maternita',
    MensAgg: 'Contratto_MensAgg',
    ProgrRetrib: 'Contratto_ProgrRetrib',
    Straordinario: 'Contratto_Straordinario',
} as const

export type ContrattoEntityKey = keyof typeof ContrattoEntity
export type ContrattoEntityDbValue = (typeof ContrattoEntity)[ContrattoEntityKey]

export default { ContrattoEntity }
