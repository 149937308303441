import { reactive } from 'vue'
import { get, has, set, unset } from 'lodash'

export interface DynamicStore {
    dynamic: any
    deleteState: (pathName?: string) => void
    getState: (pathName: string, defaultVal?: any) => any
    hasState: (pathName?: string) => boolean
    setState: (pathName: string, data: any) => void
}

export function useDynamicStore(): DynamicStore {
    const state: any = reactive({})

    function deleteState(pathName?: string) {
        pathName
            ? unset(state, pathName)
            : Object.keys(state).forEach(k => delete state[k as keyof typeof state])
    }

    function hasState(pathName?: string): boolean {
        return pathName
            ? has(state, pathName) || has(state, `${pathName}#`)
            : !!Object.keys(state).length
    }

    function getState(pathName: string, defaultVal?: any): any {
        const resMap = get(state, `${pathName}#`)
        return resMap ? new Map(Object.entries(resMap)) : get(state, pathName, defaultVal || null)
    }

    function setState(pathName: string, data: any) {
        if (data instanceof Map) pathName += '#' // suffisso per i Map
        set(state, pathName, data instanceof Map ? Object.fromEntries(data) : data)
    }

    return {
        dynamic: state,
        deleteState,
        getState,
        hasState,
        setState,
    }
}
