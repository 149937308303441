import storicoDitteViews from '@paghe/views/anagrafiche/storico/ditte'

const tabPropsDefault: TabProps = {
    GROUP: 'storicoValDitta',
    ICON: 'fa-regular fa-buildings',
    TITLE: 'Storico validità Ditte',
    CODVOCEMENU: '000200',
    CODPERMESSO: 'ANAG_DITTA',
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: Number(route.params.day),
        id: Number(route.params.id),
        idPadre: Number(route.params.idPadre),
        isAllDitta: String(route.params.isAllDitta) === '1',
        isCo: String(route.params.isCo) === '1',
    }
}

export const SETTINGS = Object.freeze({
    ELENCO: {
        TITLE: 'Elenco ditte - Storico validità - Anagrafica - Corrente - Gestione - Paghe',
        LABEL: 'Storico Ditte',
        PATH: 'storicoDitte',
        NAME: 'StoricoElencoDitte',
        TAB: { ...tabPropsDefault },
    },
    DETTAGLIO: {
        TITLE: 'Dettaglio ditta - Storico validità - Anagrafica - Corrente - Gestione - Paghe',
        LABEL: 'Dettaglio storico ditta',
        PATH: 'storicoDitte/dettaglio/:year/:month/:id/:idPadre',
        NAME: 'StoricoDettaglioDitte',
        TAB: { ...tabPropsDefault, TITLE: 'Storico validità Ditta' },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO.PATH,
            name: SETTINGS.ELENCO.NAME,
            component: storicoDitteViews.Elenco,
            meta: {
                title: SETTINGS.ELENCO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                idPaginaMsg: 'VALID',
            },
            props: true,
        },
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: propsFn,
            component: storicoDitteViews.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                idPaginaMsg: 'VALID',
                tabGroup: tabPropsDefault.GROUP,
            },
        },
    ],
}
