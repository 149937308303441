import utyDate from '@/scripts/services/date'

function checkDoppioCedolino(dipendente: any, periodoElab: any) {
    if (!dipendente || !periodoElab || !dipendente.dipteDoppioCedolino) return false

    let dataInizioCedolino = utyDate.toMoment(dipendente.dipteDoppioCedolino.dataInizio)

    if (!dataInizioCedolino) return false

    let dataInizio = utyDate.toMoment(periodoElab)
    let dataFine = utyDate.toMoment(periodoElab)?.endOf('month')
    return dataInizioCedolino.isBetween(dataInizio, dataFine, undefined, '(]')
}

function doppioCedolino(dipendente: any, periodoElab: number) {
    let periodoElabStr = periodoElab.toString()
    let periodoElabI = utyDate.toMoment(periodoElabStr, false, 'YYYYMM')
    let periodoElabF = utyDate.toMoment(periodoElabStr, false, 'YYYYMM')?.endOf('month')
    let dataInizioDC = dipendente.dipteDoppioCedolino?.dataInizio
    let dataFineDC = dipendente.dipteDoppioCedolino?.dataFine
    let mDataFineDC = utyDate.toMoment(dataFineDC)
    let prog: 'F' | 'I' | null = null
    if (checkDoppioCedolino(dipendente, periodoElabI?.format('YYYY-MM'))) {
        prog = 'I'
    } else {
        dataInizioDC = null
        if (mDataFineDC?.isBetween(periodoElabI, periodoElabF, undefined, '[)')) prog = 'F'
    }
    let data = ''
    if (dataInizioDC) {
        data = utyDate.format(dipendente.dipteDoppioCedolino.dataInizio)
    } else {
        if (mDataFineDC?.year() && mDataFineDC.year() < 9999) {
            data = mDataFineDC?.format('DD/MM/YYYY')
        }
    }
    return { prog, data }
}

function getDayDipendente(data: any) {
    let dataCedolino = utyDate.toMoment(data)
    if (!dataCedolino) return 1
    return dataCedolino.date()
}

function decodeCodPosizione(code: string): string {
    let strPosDipendente = 'n.a.'

    if (code != null) {
        switch (code.toUpperCase()) {
            case 'N':
                strPosDipendente = 'Nuovo Assunto'
                break
            case 'F':
                strPosDipendente = 'In Forza'
                break
            case 'K':
                strPosDipendente = 'Nuovo assunto in parcheggio'
                break
            case 'H':
                strPosDipendente = 'Assunto e cessato nel mese'
                break
            case 'L':
                strPosDipendente = 'Cessato nel mese'
                break
            case 'C':
                strPosDipendente = 'Cessato nei mesi precedenti'
                break
            case 'R':
                strPosDipendente = 'Richiamato'
                break
            default:
                break
        }
    }
    return strPosDipendente
}

//usato come chiave per la lsitaDipte e anche in DipendenteListStore
function getKeyDipte(dipte: any) {
    if (!dipte) return ''
    const dataInizioRapporto = dipte.dipteRapporto
        ? dipte.dipteRapporto?.dataInizio.substring(0, 10)
        : ''
    const dataInizioCedolino = dipte.dipteDoppioCedolino
        ? dipte.dipteDoppioCedolino?.dataInizio.substring(0, 10)
        : ''

    return `${dipte.id}-${dataInizioRapporto}-${dataInizioCedolino}`
}

export { checkDoppioCedolino, decodeCodPosizione, doppioCedolino, getDayDipendente, getKeyDipte }
